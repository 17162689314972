import './App.css';
import { NexhubProvider } from './Providers/provider';
import HomePage from './pages/home';


function App() {

 

  return (
      <NexhubProvider>
        <HomePage />
      </NexhubProvider>
  );
}

export default App;