import './style.css'

import MoneyIcon from '../../assets/moneyicon.png'
import TimeIcon from '../../assets/time.png'
import ArrowsIcon from '../../assets/setas.png'


const Publicity = () => {

    return(
        <div className='container_publicity'>

            <div className='box'>
                <img src={MoneyIcon} alt='money_icon' className='money_icon'/>

                <div className='text_money'>
                    <h1 className='title_money'>Aumento nas Vendas</h1>
                    <p className='text_money'>Deixe a Nexhub cuidar das tarefas repetitivas e foque no que realmente importa: fechar negócios.</p>
                </div>
            </div>

            <div className='box'>
                <img src={TimeIcon} alt='money_icon' className='money_icon'/>

                <div className='text_money'>
                    <h1 className='title_money'>Maior Produtividade</h1>
                    <p className='text_money'>Deixe a Nexhub cuidar das tarefas repetitivas e foque no que realmente importa: fechar negócios.</p>
                </div>
            </div>

            <div className='box'>
                <img src={ArrowsIcon} alt='money_icon' className='money_icon'/>

                <div className='text_money'>
                    <h1 className='title_money'>Tomada de Decisões</h1>
                    <p className='text_money'>Deixe a Nexhub cuidar das tarefas repetitivas e foque no que realmente importa: fechar negócios.</p>
                </div>
            </div>

        </div>
    )
}

export default Publicity

