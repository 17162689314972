import './styled.css'

import APEX from '../../assets/APEX.png'
import BEMBRASIL from '../../assets/BEM BRASIL.png'
import DSM from '../../assets/DSM.png'
import GOOGLE from '../../assets/GOOGLE.png'
import GRUPOPETROPOLIS from '../../assets/GRUPO PETROPOLIS.png'
import ISACTEEP from '../../assets/ISA CTEEP.png'
import JOHNSON from '../../assets/JOHNSON.png'
import LOREAL from '../../assets/LOREAL .png'
import SATA from '../../assets/SATA.png'
import SIMPLEORGANIC from '../../assets/SIMPLE ORGANIC.png'
import SINGENTA from '../../assets/SINGENTA.png'
import STEFANNI from '../../assets/STEFANINI.png'
import TOYOTA from '../../assets/TOYOTA.png'

import { useState, useEffect, useContext } from 'react';
import { NexhubContext } from '../../Providers/provider';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CustomArrow = ({ onClick, direction }) => {

  const arrowStyle = {
    color: 'transparent',
  };
  return (
    <div className={`custom-arrow custom-arrow-${direction}`} style={arrowStyle} onClick={onClick}>
      {direction === 'prev' ? '<' : '>'}
    </div>
  );
};


const CarouselComponent = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const imagesArray = [APEX, BEMBRASIL, DSM, GOOGLE, GRUPOPETROPOLIS, ISACTEEP, JOHNSON, LOREAL, SATA, SIMPLEORGANIC, SINGENTA, STEFANNI, TOYOTA]
    
    const getSlidesToShow = () => {
        if (windowWidth <= 337) return 2;
        if (windowWidth <= 496) return 3;
        if (windowWidth <= 787) return 4;
        if (windowWidth <= 959) return 5;
        if (windowWidth <= 1246) return 6;
        return 7;
      };
    
      const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: getSlidesToShow(),
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        prevArrow: <CustomArrow direction="prev" />,
        nextArrow: <CustomArrow direction="next" />,
      };

      useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return(

      <Slider {...settings}>
      {imagesArray.map((image, index) => (
        <div key={index} className='card'>
          <img className='card_image' src={image} alt={`logo_marcas_${index}`} />
        </div>
      ))}
    </Slider>

    )
}

export default CarouselComponent