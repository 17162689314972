import './style.css'
import { TextBox } from './style'

import GlobeVideo from '../../video/background_site_azul.mp4'
import Mouse from '../../assets/Mouse.png'
import MouseMobile from '../../assets/seta para baixo.png'

import React, { useState, useEffect, useContext } from 'react';
import { NexhubContext } from '../../Providers/provider'

const Globe = () => {

  const {handleScrollToMouse, whatsAppApi} = useContext(NexhubContext)

  const words = ['Criamos seu site ou ecommerce como uma ferramenta de vendas de alta conversão'];
  
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [altura, setAltura] = useState(window.innerHeight);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    const handleResize = () => {
      setAltura(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


    return (
        
    <div className='container_globe'>

        <TextBox height={altura}>

            <h2 className='subTitle_text'>{words[currentWordIndex]}</h2>
            <h1 className='title_text'>Venda até <strong>10x</strong> mais...</h1>

            <p className='description_text'>Usamos o poder da <strong>IA</strong> para <strong>estruturar sites</strong> e</p>
            <p className='description_text'>estratégias digitais com alta conversão em vendas.</p>

            <div className='box_button_globe'>
              <button className='button_globe' onClick={whatsAppApi}>Quero vender até <strong>10x</strong> mais</button>
            </div>

            {window.innerWidth >= 768 ? <img className='mouse_pick' src={Mouse} alt='mouse_icon' onClick={handleScrollToMouse}/>
             : <img className='mouse_mobile' src={MouseMobile} alt='mouse_mobile_icon' onClick={handleScrollToMouse}/>}

        </TextBox>

          <video
          src={GlobeVideo}
          width="100%"
          height="900px"
          autoPlay
          muted
          loop
        />
        <div className='curve'></div>
    </div>
       
      );
}

export default Globe