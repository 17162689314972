import styled from 'styled-components';

 export const TextBox = styled.div`

    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
    align-items: center;

    ${props =>
        props.height >= 810
          ? `
            top: 45%;
          `
          : props.height >= 430
          ? `
            top: 60%;
          `
          : ''
      }
`