
import Assessment from "../component/assessment/assessment";
import Benefits from "../component/benefits/benefits";
import Carousel3D from "../component/carousel3d/carousel3d";
import Footer from "../component/footer/footer";
import Globe from "../component/globe/globe"
import Header from "../component/header/header"
import Publicity from "../component/publicity/publicity";
import CarouselComponent from './../component/carousel/carousel';
// import Loading from './../component/Loading/loading';

// import { useEffect, useState } from "react";

const HomePage = () => {

    // const [isLoading, setIsLoading] = useState(true);

        // useEffect(() => {
            
        //     setTimeout(() => {
        //     setIsLoading(false); 
        //     }, 1000); 
        // }, []);

    return(
        <>
          <Header />
          <Globe />
          <CarouselComponent />
          <Carousel3D  />
          <Publicity />
          <Benefits />
          <Assessment />
          <Footer />
        </>
    )
}

export default HomePage