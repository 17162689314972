import './style.css';
import YoutubeIcon from '../../assets/youtubeIcon.png'
import LinkIcon from '../../assets/linkedinicon.png'
import InstagramIcon from '../../assets/instagramicon.png'
import NexhubIcon from '../../assets/logo_nexhub_branco.png'
import { useContext } from 'react';
import { NexhubContext } from '../../Providers/provider';

const Footer = () => {

    const {handleScrollToUp, scrollToTargetBeneficios, scrollToTargetCases} = useContext(NexhubContext)

    return(

        <footer className='footer'>
            <img className='nexhub_icon' src={NexhubIcon} alt='nexhub_icon'/>

            <nav className='box_buttons'>
                {/* <button className='button_nav'onClick={handleScrollToUp} >Inicio</button>
                <button className='button_nav'></button>
                <button className='button_nav'onClick={scrollToTargetBeneficios} >Benefícios</button> */}
                {/* <button className='button_nav' onClick={scrollToTargetCases}>Cases de Sucesso</button> */}
            </nav>

            <div className='box_icons_social'>

                <img className='instagram_icon' src={InstagramIcon} alt='instagram_icon'/>
                <img className='linkedin_icon' src={LinkIcon} alt='linkedin_icon'/>
                <img className='youtube_icon' src={YoutubeIcon} alt='youtube_icon'/>

            </div>
        </footer>
    )
}

export default Footer