import './style.css'

import Divider from '../../assets/separador.png'
import X from '../../assets/logo_nexhub_x.png'
import CardSlider from '../../assets/rentaguleAvaliacao.png'

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


import { useState, useEffect, useContext } from 'react';
import { NexhubContext } from '../../Providers/provider';

const CustomArrow = ({ onClick, direction }) => {

  const arrowStyle = {
    color: 'transparent',
  };
  return (
    <div className={`custom-arrow custom-arrow-${direction}`} style={arrowStyle} onClick={onClick}>
      {direction === 'prev' ? '<' : '>'}
    </div>
  );
};


const Assessment = () => {

  const {buttonCasesDeSucesso, buttonFooterUpCases, buttonCasesMobile, whatsAppApi} = useContext(NexhubContext)

  const [slidesToShow, setSlidesToShow] = useState(window.innerWidth >= 768 ? 2 : 1);

  useEffect(() => {
    const handleResize = () => {
      setSlidesToShow(window.innerWidth >= 768 ? 2 : 1);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: true,
    prevArrow: <CustomArrow direction="prev" />,
    nextArrow: <CustomArrow direction="next" />,
  };
  
    return(
        <>
        <div className='container_assessment'>

            <div className='box_img_assessment'>

                <img className='divider_assessment' src={Divider} alt='divisor' />

             </div>

            {/* <div ref={buttonFooterUpCases} className='x_and_text_assessment'>
                <img className='X_assessment' src={X} alt='X' />
                <p ref={buttonCasesDeSucesso} className='text_assessment'>O que nossos <strong>clientes</strong> estão dizendo</p>
                <div ref={buttonCasesMobile} className='blur5'></div>
            </div> */}

        </div>

        {/* <Slider {...settings}>
          <div className='container_card_assessment'>
            <img className='card_slider' src={CardSlider} alt='card_slider'/>

            <div className='container_card_slider'>
            <p className='text_slider_p'><strong>A Nexhub nos ajudou a aumentar nossas vendas em 20%.</strong> A plataforma é fácil de usar e fornece insights valiosos para os vendedores.</p>
            <p className='text_slider_client'>- [nome do cliente]</p>

            </div>
          </div>


          <div className='container_card_assessment'>
            <img className='card_slider' src={CardSlider} alt='card_slider'/>

            <div className='container_card_slider'>

            <p className='text_slider_p'><strong>A Nexhub nos ajudou a economizar tempo e dinheiro.</strong> A plataforma automatiza tarefas repetitivas, o que nos permite nos concentrar em clientes qualificados.</p>
            <p className='text_slider_client'>- [nome do cliente]</p>


            </div>
          </div>

          <div className='container_card_assessment'>
            <img className='card_slider' src={CardSlider} alt='card_slider'/>

            <div className='container_card_slider'>

            <p className='text_slider_p'><strong>A Nexhub nos ajudou a aumentar nossas vendas em 20%.</strong> A plataforma é fácil de usar e fornece insights valiosos para os vendedores.</p>
            <p className='text_slider_client'>- [nome do cliente]</p>
            


            </div>
          </div>
        </Slider> */}

            <div className='button_container' ref={buttonCasesDeSucesso}>
              <button className='button_specialisty' ref={buttonCasesMobile} onClick={whatsAppApi} >Quero falar com especialista</button>
            </div>
            <div className='blur6'ref={buttonFooterUpCases}></div>

        </>
    )
}

export default Assessment