import './style.css'
import LogoNexhubBranco from '../../assets/logo_nexhub_branco.png'
import MenuMobileimg from '../../assets/menuMobile.png'

import MenuMobile from '../menuMobile/menuMobile';

import { useContext, useState, useEffect } from 'react';
import { NexhubContext } from '../../Providers/provider';

const Header = () => {

    const [showMenuMobile, setShowMenuMobile] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    const { handleScrollToUp, handleScrollToCases, handleScrollToBeneficios, handleScrollToSobre } = useContext(NexhubContext)

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);



    const toggleMenuMobile = () => {
        if(showMenuMobile === false) {
            setShowMenuMobile(true)
        } else {
            setShowMenuMobile(false)
        }
    };

    return(

      <>
      {window.innerWidth <= 768 ? (
          <header className='header_mobile'>
              <img className='logo_hub_mobile' src={LogoNexhubBranco} alt='logo_nexhub_branco'/>
              <div className='container_img_'>
                  {/* {showMenuMobile ? (
                      <MenuMobile />
                  ) : null
                      
                  } */}
                  <img
                          className='menu_mobile'
                          src={MenuMobileimg}
                          alt='menu_mobile'
                          onClick={toggleMenuMobile}
                      />
              </div>
          </header>
      ) : (
          <header className="header">
              <img className='logo_hub' src={LogoNexhubBranco} alt='logo_nexhub_branco'/>
              <nav className='container_buttons'>
                  {/* <button onClick={handleScrollToUp} className='button'>Inicio</button>
                  <button className='button' onClick={handleScrollToSobre}>Sobre</button>
                  <button className='button'onClick={handleScrollToBeneficios} >Benefícios</button>
                  <button className='button' onClick={handleScrollToCases}>Cases de Sucesso</button> */}
              </nav>
          </header>
      )}
  </>
    )
}

export default Header