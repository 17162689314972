import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalStyle from './styles/global';
import { NexhubProvider } from './Providers/provider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <NexhubProvider>
    <GlobalStyle />
    <App />
    </NexhubProvider>
  </React.StrictMode>
);
reportWebVitals();
