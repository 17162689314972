import './carouselstyled.css'
import product1 from '../../assets/product1.png'
import product2 from '../../assets/product2.png'
import product3 from '../../assets/product3.png'
import product4 from '../../assets/product4.png'
import product5 from '../../assets/product5.png'

import Divider from '../../assets/separador.png'
import X from '../../assets/logo_nexhub_x.png'


import left from '../../assets/Setadireita.png'
import right from '../../assets/Setaesquerda.png'

import { NexhubContext } from '../../Providers/provider'
import { useState, useEffect, useContext } from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


  const CustomDots = ({ onClick, active }) => {
    return (
      <div
        style={{
          width: '5px',
          height: '5px',
          background: active ? 'transparent' : 'transparent',
          borderRadius: '50%',
          margin: '0 5px',
          cursor: 'pointer',
        }}
        onClick={onClick}
      />
    );
  };

const Carousel3D = () => {

    const {buttonSobre, buttonSobreMobile, buttonFooter, whatsAppApi} = useContext(NexhubContext)

    const [currentSlide, setCurrentSlide] = useState(0);

    const updateCurrentSlide = (current) => {
      setCurrentSlide(current);
    };
    
    
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0',
        prevArrow: <img id='right' src={right} alt="Seta para a Esquerda" />,
        nextArrow: <img  id='left' src={left} alt="Seta para a Direita" />,
        customPaging: (i) => <CustomDots />,
        appendDots: (dots) => (
          <div>
            <ul style={{ background: '#00051B'}}> {dots} </ul>
          </div>
        ),
        beforeChange: (current, next) => updateCurrentSlide(next),
      };

      useEffect(() => {
      }, [currentSlide]);

      const informArray = [{image: product1, title: 'Alcance mais clientes com a automação de prospecção', text: 'Com a Nexhub, você pode automatizar a captura e gestão de leads qualificados. Isso significa mais tempo se concentrando na geração de oportunidades e fechamento negócios reais.', textButton: 'Quero vender mais!', word: 'prospecção', word2: 'automatizar a captura e gestão de leads'},
      {image: product2, title: 'Mais de 23 milhões de potenciais clientes, comece agora!', text: 'Com acesso a uma base de dados de mais de 23 milhões de leads potenciais, você pode atingir mais clientes. Nossa plataforma utiliza IA para identificar leads qualificados e segmentá-los com base em seus interesses e necessidades.', textButton: 'Entre em contato conosco', word: '23 milhões', word2: '23 milhões de leads'},
      {image: product3, title: 'Geração de leads qualificadas com IA', text: 'A Nexhub emprega IA para identificar leads qualificados com base em dados históricos e tendências, permitindo a segmentação com base nos interesses e necessidades dos leads. Isso possibilita que você dedique mais tempo a clientes qualificados, melhorando suas chances de fechar negócios.', textButton: 'Quero vender mais!', word: 'Geração de leads', word2: 'identificar leads qualificados com base em dados históricos e tendências'},
      {image: product4, title: 'Aumente sua produtividade com a automação de vendas', text: 'A Nexhub automatiza tarefas como envio de e-mails, agendamento de reuniões e acompanhamento de leads, permitindo que você dedique mais tempo aos clientes qualificados e, assim, aumente suas chances de fechar negócios.', textButton: 'Entre em contato conosco' , word: 'automação de vendas', word2: 'automatiza tarefas'},
      {image: product5, title: 'Tome decisões de vendas mais inteligentes com análise de dados', text: 'A Nexhub usa IA para analisar dados de vendas para identificar padrões e tendências. Isso pode ajudá-lo a tomar decisões de vendas mais inteligentes, como quais leads para priorizar e quais estratégias de vendas usar.', textButton: 'Saiba mais' , word: 'análise de dados', word2: 'IA para analisar dados de vendas para identificar padrões e tendências'}]
      
      const highlightWord1 = (text, word) => {
        const regex = new RegExp(`\\b${word}\\b`, 'gi');
        return text.replace(regex, `<span style="font-weight: bold; color: #0047FF;">${word}</span>`);
      };

      const highlightWord2 = (text, word) => {
        const regex = new RegExp(`\\b${word}\\b`, 'gi');
        return text.replace(regex, `<span style="font-weight: bold;">${word}</span>`);
      };

    return(
<>
      <div className='container'>

      <div className='box_img' ref={buttonFooter}>

          <img className='divider' src={Divider} alt='divisor' />

      </div>

      <div className='x_and_text'>
          <img className='X' src={X} alt='X' />
          {informArray.map((item, index) => (
        <p key={index} ref={buttonSobre} className={`text ${currentSlide === index && item.title === 'Geração de leads qualificadas com IA' ? 'wide-text' : ''}`}>
          {currentSlide === index &&
            <span dangerouslySetInnerHTML={{ __html: highlightWord1(item.title, item.word) }} />
          }
        </p>
      ))}
          <div ref={buttonSobreMobile} className='blur1'></div>
      </div>
  </div>

  <Slider className='carousel2' {...settings}>
      {informArray.map((info, index) => (
        <div key={index} id='cardFlex' className={`card ${index === currentSlide ? 'active' : ''}`}>
          <img
            className="card_image_"
            src={info.image}
            alt={`Imagem ${index + 1}`}
            style={{
              width: index === currentSlide ? '150%' : '100%',
              height: index === currentSlide ? '30rem' : '400px',
              opacity: index === currentSlide ? '1' : '0.3',
              marginTop: index === currentSlide ? '' : '90px',
              transition: 'width 1s ease, height 1s ease',
            }}
          />
        </div>
      ))}
    </Slider>

      <div className='container_text'>

        <div className='container_big_text'>
          {informArray.map((item, index) => (
          <p key={index} ref={buttonSobre} className='big_text'>
            {currentSlide === index &&
              <span dangerouslySetInnerHTML={{ __html: highlightWord2(item.text, item.word2) }} />
            }
          </p>
        ))}
        </div>
                {informArray.map((item, index)=> (
                  currentSlide === index ? <button key={index} className='button_experience' onClick={whatsAppApi}>{item.textButton}</button> : ''           
                ))}
                 
      </div>
</>
    )
}

export default Carousel3D