import './style.css'
import Divider from '../../assets/separador.png'
import X from '../../assets/logo_nexhub_x.png'

import Ok from '../../assets/ok.png'
import Benefit1 from '../../assets/Group 59.png'
import Benefit2 from '../../assets/benefit2.png'
import Benefit3 from '../../assets/Group 65.png'
import Benefit4 from '../../assets/benefit4.png'

import { useContext } from 'react'
import { NexhubContext } from '../../Providers/provider'


const Benefits = () => {

    const {buttonBeneficios, buttonBeneficiosMobile, buttonFooterUpBeneficios} = useContext(NexhubContext)

    return(

        <>
        <div className='container_benefits'>

            <div className='box_image'>

                <img className='divider_' src={Divider} alt='divisor' />

            </div>

            <div ref={buttonBeneficios} className='x_and_text_box'>
                <img  className='X_' src={X} alt='X' />
                <p className='text_' ref={buttonFooterUpBeneficios}><strong>Acelere</strong> as suas vendas com a Nexhub</p>
                <div ref={buttonBeneficiosMobile} className='blur2'></div>
            </div>
        </div>

        <div className='container_box_benefits'>

            <div className='box_container'>

                <img className='img_b1' src={Benefit1} alt='box_benefit1' />

                <div className='text_container'>
                    <p className='benefit_text'><img className='ok_icon' src={Ok} alt='ok_icon'/>Segmentamos leads com base em seus interesses e necessidades</p>
                    <p className='benefit_text'><img className='ok_icon' src={Ok} alt='ok_icon'/>Geramos leads qualificados com mais eficiência</p>
                </div>
                    
            </div>

            <div className='box_container2'>
                <div className='blur3'></div>

                <img className='img_b1' src={Benefit2} alt='box_benefit1' />

                <div className='text_container'>
                    <p className='benefit_text2'><img className='ok_icon' src={Ok} alt='ok_icon'/>Aumente suas chances de fechar negócios</p>
                    <p className='benefit_text2'><img className='ok_icon' src={Ok} alt='ok_icon'/>Aumente suas vendas e alcance novos clientes</p>
                </div>

                    
            </div>

            <div className='box_container'>
                <div className='blur4'></div>

                <img className='img_b1' src={Benefit3} alt='box_benefit1' />

                <div className='text_container'>
                    <p className='benefit_text'><img className='ok_icon' src={Ok} alt='ok_icon'/>Concentre-se em clientes qualificados</p>
                    <p className='benefit_text'><img className='ok_icon' src={Ok} alt='ok_icon'/>Identifique padrões e tendências</p>
                </div>
                    
            </div>

            <div className='box_container2'>

                <img className='img_b1' src={Benefit4} alt='box_benefit1' />

                <div className='text_container'>
                    <p className='benefit_text2'><img className='ok_icon' src={Ok} alt='ok_icon'/>Automatize tarefas repetitivas</p>
                    <p className='benefit_text2'><img className='ok_icon' src={Ok} alt='ok_icon'/>Tome decisões de vendas mais inteligentes</p>
                </div>
                    
            </div>

                {/* <div className='container_button_saiba_mais'>

                    <button className='button_benefit' >Saiba mais</button>

                </div> */}

        </div>
        </>
    )
}

export default Benefits