import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

    :root{
        --blue1: #0047FF;
        --blue2: #00A3FF;
        --blue3: #00A3FF;
        --black1: #1B1E2A;
        --black2: #0B0F14;
        --black3: #00051B;
        --white: #ffff;
        --gray: #E5E5E5;
    }

    button{
        cursor: pointer;
    }

`
export default GlobalStyle